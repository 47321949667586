/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages



        $('.multiple-items').slick({
          arrows: true,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 800,
          easing: 'swing',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false
              }
            }
          ]
        });



        $('.slider-occasions').slick({
          arrows: true,
          speed: 400,
          easing: 'swing',
          asNavFor: '.slider-occasions-thumb'
        });

        $('.slider-occasions-thumb').slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false,
          speed: 500,
          easing: 'swing',
          asNavFor: '.slider-occasions',
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 4
              }
            }
          ]
        });



        var currentwidth = $('.featured').width();
        //var currentheight = $('.featured').height();
        var extrawidth = ($(window).width() - $('.wrap').width()) / 2;
        $('.featured-inner').width(extrawidth + currentwidth);
        //$('.multiple-items .slick-slide').height(currentheight);










        $(window).resize(function () {
          var currentwidth = $('.featured').width();
          //var currentheight = $('.featured').height();
          var extrawidth = ($(window).width() - $('.wrap').width()) / 2;
          $('.featured-inner').width(extrawidth + currentwidth);
          //$('.multiple-items .slick-slide').height(currentheight);
        });

        $('.slider-default').slick({
          arrows: true,
          dots: true,
          autoplay: false,
          autoplaySpeed: 5000,
          speed: 800,
          slidesToShow: 3,
          slidesToScroll: 3,
          easing: 'swing',
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });

        $('.slider-home').slick({
          arrows: true,
          autoplay: true,
          autoplaySpeed: 7000,
          speed: 800,
          dots: true,
          easing: 'swing',
          fade: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false
              }
            }
          ]
        });

        $('#back-to-top').on('click', function (e) {
          e.preventDefault();
          $('html,body').animate({
            scrollTop: 0
          }, 600);
        });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

document.addEventListener('DOMContentLoaded', function () {
  var menuItems = document.querySelectorAll('.menu-item-has-children > a');

  menuItems.forEach(function (item) {
    var isSubmenuOpen = false;

    item.addEventListener('click', function (event) {
      var parent = this.parentElement;
      var submenu = parent.querySelector('.dropdown-menu');

      // Check of het submenu bestaat
      if (submenu) {
        if (!isSubmenuOpen) {
          event.preventDefault(); // Voorkomt dat de link wordt gevolgd
          submenu.style.display = (submenu.style.display === 'block') ? 'none' : 'block'; // Toggle submenu
          isSubmenuOpen = true;

          // Sluit submenu als er buiten wordt geklikt
          document.addEventListener('click', function closeSubmenu(e) {
            if (!parent.contains(e.target)) {
              submenu.style.display = 'none';
              isSubmenuOpen = false;
              document.removeEventListener('click', closeSubmenu);
            }
          });
        } else {
          isSubmenuOpen = false; // Sta toe dat de link gevolgd wordt
        }
      }
    });
  });
});